import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Close to Me" />

		<h2>Close to Me</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST14 close front.jpg"
			alt="Jesse Rivest - Close to Me - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on March 2, 2023.<br />
			(The streaming services may be a while before they have the audio; my distributor rejected my cover art and thus delayed the submission.)<br />
			(You can of course listen to it at <a href="//store.jesserivest.com/music">Bandcamp</a>!)
		</p>

		<h3>Words from Jesse</h3>
		<p>
			I returned from a one-month trip to my hometown, Kelowna, and knew I wouldn't have anything prepared for an early 2023 single release.
			I guess the pressure has been on in recent years to keep trickling out music in a regular fashion;
			I've been aiming for every two or three months.
			I knew I wouldn't have time to produce anything fancy, so I went through my backlog looking for something simple yet endearing.
			That's when I found a <span className="italic">feeling</span> I had recorded on March 17, 2021, which I had called <span className="italic">ascending idea</span>.
		</p>
		<p>
			Zip back to the present—February 2023 at the time—when I quickly set up a ribbon mic for my guitar and my ETL Edwina for my voice.
			A couple passes and voila: I now had a warm, intimate recording of a new single, <span className="italic">Close to Me</span>.
			One Sunday morning shortly after, I mounted a 1960's Nikon 50-mm, f-1.4 Nikkor-S lens to my digital film camera;
			set the filming settings and fixed the focus; and handed the camera over to my partner, Jane, asking her to film me.
		</p>
		<p>
			"Don't worry about the focus. Start close, pan away, and slowly walk backwards. Take in the scene, then slowly approach me again."<br />
			"Ok!"
		</p>
		<p>
			A little shaky, but she did a great job with great feeling, and the lens did the rest.
			We hope you enjoy <span className="italic">Close to Me</span>; please share it if you do!
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Close to Me (3:12)<br />
				T3180383517 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00001 - © Jesse Rivest 2023 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST14, © Jesse Rivest 2023, All Rights Reserved<br />
			UPC/EAN: 0198009110483<br />
			Released: March 2, 2023
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song written and performed by Jesse Rivest.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Album cover photography by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.<br />
			Video by Jesse Rivest and Jane Lino.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Tico de Moraes for his ears and feedback.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST14 close back.png"
			alt="Jesse Rivest - Close to Me - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h4>Music video</h4>
		<div className="main--centerwrapper">
			<iframe
			className="main--centered"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/eHKHtglYaHw"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
